import React from 'react'
import ReactGA from 'react-ga';
// eslint-disable-next-line
import { ToastContainer, toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import fbLogo from '../../assets/img/fb.png'
import twLogo from '../../assets/img/tw.png'
import lnLogo from '../../assets/img/ln.png'

import 'react-toastify/dist/ReactToastify.css'

import app from '../../data/app.json'
import videos from "../../data/videos.json"

function Social(params) {

    const lang = window.localStorage.getItem('lang')
    const langData = lang === "en" ? app[0].en : app[0].fr
    const url_facebook = 'http://www.facebook.com/sharer.php?u=' + window.location.href
    const url_twitter = 'https://twitter.com/intent/tweet?url=' + window.location.href

    const shareFB = () => {

        sendAnalytics("social share", "facebook", params.source);
        let options = 'toolbar=0,status=0,resizable=1,width=626,height=436'
        window.open(url_facebook, 'sharer', options)
    }

    const shareTW = () => {
        // console.log(params.source)
        sendAnalytics("social share", "twitter", params.source);
        let td = langData.shares.twitter_description
        if (params.source !== "site from footer") {
            td = langData.shares.custom.replace('{}', videos[0][params.source]['text_' + window.localStorage.getItem('lang')]['title'])
        }
        let options = 'toolbar=0,status=0,resizable=1,width=626,height=436';
        window.open(url_twitter + '&text=' + td, 'sharer', options);
    }

    const notify = (copy) => {

        toast(copy);
        sendAnalytics("social share", "url", params.source);

    }

    function sendAnalytics(category, event, label) {
        ReactGA.event({
            category: category,
            action: event,
            label: label
        });
    }

    return (
        <>
            <ToastContainer toastClassName="dark-toast" hideProgressBar={true} autoClose={1400} />
            <div className="shareLinks">
                <button onClick={shareFB}>
                    <img src={fbLogo} alt="Facebook" />
                </button>

                <button onClick={shareTW}>
                    <img src={twLogo} alt="Twitter" />
                </button>

                <CopyToClipboard text={window.location.href} onCopy={() => notify(langData.text.copied)}>
                    <img src={lnLogo} alt="Url" style={{ cursor: "pointer" }} />
                </CopyToClipboard>
            </div>

        </>
    )
}

export default Social