import React, { useState } from 'react'
// import ReactPlayer from 'react-player'
import VideoPlayerx from './index.js';
import ReactGA from 'react-ga';
import UAParser from 'ua-parser-js'
ReactGA.initialize('UA-163442254-1');


const VideoPlayer = (props) => {
    const { video_id, video_url, video_txt_fr, video_txt_en, show_txt_en, show_txt_fr } = props
    // eslint-disable-next-line 
    const [ended, setEnded] = useState(false)

    function sendAnalytics(category, event, label) {
        ReactGA.event({
            category: category,
            action: event,
            label: label
        });
    }

    let a20 = false
    let a40 = false
    let a60 = false
    let a80 = false
    function onPlayerReady(player) {
        if (video_id !== "ketchup-kiss") { // do not add subtitles for this video, everything else yes
            player.addRemoteTextTrack({ src: video_txt_en, label: "English", default: show_txt_en, tech: "anonymous" });
            player.addRemoteTextTrack({ src: video_txt_fr, label: "French", default: show_txt_fr, tech: "anonymous" });
        }

        // player.bigPlayButton.hide();
        player.crossOrigin("anonymous");
        // player.fluid(true);
        player.fill(true);
        player.responsive(true);
        player.poster("/img/black.png");

        let a = new UAParser()
        if (a.getDevice().type === "mobile" || a.getDevice().type === "tablet") {
            // player.bigPlayButton.show();

            document.getElementsByClassName("vjs-big-play-button")[0].style.visibility = "visible";

            // player.muted()
        } else {
            if (a.getBrowser().name === 'Safari') {
                // alert(a.getBrowser().name)
                document.getElementsByClassName("vjs-big-play-button")[0].style.visibility = "visible";
            } else {
                player.play() //autoplay in desktop
                // document.getElementsByClassName("vjs-big-play-button")[0].style.visibility = "visible";
            }

        }



    }
    let userActive = true
    function onVideoEnd(player) {
        document.getElementById("close-player-icon").style.display = "block";
        sendAnalytics("video player", "ended", video_id);
        setEnded(true);
        setTimeout(function () {
            if (!userActive) {
                sendAnalytics("video player", "automatic_ended", video_id);
                window.location = "/" + window.localStorage.getItem('lang') + "/movie/" + video_id
            }
        }, 1000);

    }

    function onVideoPlay(player) {
        sendAnalytics("video player", "started", video_id)
    }
    function onUserActive() {
        userActive = true
        document.getElementById("close-player-icon").style.display = "block";
    }
    function onUserInactive() {
        userActive = false
        document.getElementById("close-player-icon").style.display = "none";

    }
    function onVideoTimeUpdate(time, duration) {

        let played = (time * 100) / duration

        if (played >= 20 && played < 40 && a20 === false) {
            sendAnalytics("video player", "progress", video_id + "#20%");
            a20 = true
        }
        if (played >= 40 && played < 60 && a40 === false) {
            sendAnalytics("video player", "progress", video_id + "#40%");
            a40 = true
        }
        if (played >= 60 && played < 80 && a60 === false) {
            sendAnalytics("video player", "progress", video_id + "#60%");
            a60 = true
        }
        if (played >= 80 && a80 === false) {
            sendAnalytics("video player", "progress", video_id + "#80%");
            a80 = true
        }

    }
    return (
        <div className='player-wrapper'>

            <VideoPlayerx
                controls={true}
                src={video_url}
                // poster={this.state.video.poster}
                // width="100% !important;"
                // autoplay={true}
                height="100%"
                onReady={onPlayerReady.bind(this)}
                onPlay={onVideoPlay.bind(this)}
                // onPause={this.onVideoPause.bind(this)}
                onTimeUpdate={onVideoTimeUpdate.bind(this)}
                // onSeeking={this.onVideoSeeking.bind(this)}
                // onSeeked={this.onVideoSeeked.bind(this)}
                onEnd={onVideoEnd.bind(this)}
                onUserActive={onUserActive.bind(this)}
                onUserInactive={onUserInactive.bind(this)}
            />
        </div>


    )
}

export default VideoPlayer
