import React from 'react'
import app from '../../data/app.json'
import ProductImg from '../../assets/img/product.png'
import bgImage from '../../assets/img/shutterstock_1358731034.jpg'
export default function ProductSlide() {


    const lang = window.localStorage.getItem('lang')
    const langData = lang === "en" ? app[0].en : app[0].fr

    return (
        <div className="movieSlide" style={{ backgroundImage: `url(${bgImage})` }} >

            <div className="movieSlideGradient"></div>

            <div className="movieSlidePContent">

                <div className="movieProdCol movieProdColLeft">

                    <div className={"snack "+lang}>
                        {langData.text.snack_top}<br />
                        {langData.text.snack_bottom}<br />
                        <center>
                        <button className="hero_cta" onClick={() => { window.open('https://www.walmart.ca/' + lang + '/ip/doritos-ketchup-tortilla-chips/6000196078571') }}>{langData.text.get_chips}</button>
                        </center>
                    </div>

                </div> { /* movieSlideCol */}

                <div className="movieProdCol movieProdColRight">
                    <img src={ProductImg} alt="Doritos Ketchup" className="productImage" />
                </div> { /* movieSlideCol */}

            </div> { /* movieSlideContent */}


        </div>

    )
}
