import React from 'react'
import PlayButton from '../playButton/PlayButton'

export default function MovieSlide(props) {

    const { title, backdrop_poster, title_image, movie_link, sumary, meta } = props

    return (
        <div className="movieSlide" style={{ backgroundImage: `url(${backdrop_poster})` }}>

            <div className="movieSlideGradient"></div>
            <div className="movieSlidePlay hide-s">
                <PlayButton movie_id={movie_link} />
            </div>

            <div className="movieSlideContent">

                <div className="movieSlideCol">

                    <div className="movieInfo">

                        <div className={'movieSlideTitle '+movie_link}>
                            <img src={title_image} alt={title} />
                        </div>

                        <div className="movieSumary" dangerouslySetInnerHTML={{ __html: sumary }}>

                        </div>

                        <div className="movieMeta" dangerouslySetInnerHTML={{ __html: meta }}>
                        </div>

                    </div>

                </div>

                <div className="movieSlideCol movieSlidePlayCol">
                    <div className="movieSlidePlay smallPlay hide-xl">
                        <PlayButton movie_id={movie_link} />
                    </div>
                </div>

            </div>
        </div>
    )
}
