import React from 'react'
import DKPLogo from '../../assets/img/app_logo.svg'
import AgeForm from './AgeForm'
import ReactGA from 'react-ga'
import gtag, { install } from 'ga-gtag';
export default function AgeGate() {
    ReactGA.initialize('UA-163442254-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    install('DC-2614879');

    if (window.localStorage.getItem('lang') == 'fr') {
        gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-2614879/agega0/ketch00+standard'
        });
    } else {
        gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-2614879/agega0/ketch0+standard'
        });
    }

    const { body } = document;
    const script = document.createElement('noscript');
    if (window.localStorage.getItem('lang') == 'fr') {
        script.text = '<img src="https://ad.doubleclick.net/ddm/activity/src=2614879;type=agega0;cat=ketch00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/>';
    } else {
        script.text = '<img src="https://ad.doubleclick.net/ddm/activity/src=2614879;type=agega0;cat=ketch0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/>';
    }
    body.insertBefore(script, body.firstChild);

    return (
        <div className="ageGateContainer">
            <div className="ageGate">
                <div className="ageGateLogo">
                    <img src={DKPLogo} alt="Doritos Ketchup+" />
                </div>
                <div className="ageGateContent">
                    <AgeForm />
                </div>
            </div>
        </div>
    )
}
