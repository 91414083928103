import React from 'react'
import Slider from 'react-slick'

import MovieSlide from '../movieSlide/MovieSlide'
import ProductSlide from '../productSlide/ProductSlide'

import carouselData from '../../data/carousel.json'
import carouselDataFr from '../../data/carousel-fr.json'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function HeroCarousel() {

    const lang = window.localStorage.getItem('lang')

    var count = 1
    let cdata
    if (lang === "fr") {
        cdata = carouselDataFr
    } else {
        cdata = carouselData
    }

    let slides = cdata.map(data => {
        let textData = lang === "en" ? data.en : data.fr
        if (count < cdata.length) {
            count += 1
            return (
                <MovieSlide
                    key={Math.random() * 10}
                    title={textData.title}
                    backdrop_poster={data.poster_url}
                    title_image={textData.title_logo}
                    sumary={textData.description}
                    meta={textData.meta}
                    movie_link={data.video_id}
                />
            )
        } else {
            count += 1
            return (
                <ProductSlide key={Math.random() * 10} />
            )
        }

    })

    const settings = {
        dots: true,
        infinite: true,
        draggable: true,
        speed: 1200,
        easing: 'linear',
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5200
    }


    return (
        <div className="carouselContainer">
            <Slider {...settings} >
                {slides}
            </Slider>
        </div>
    )
}

export default HeroCarousel