import React, { useState, useEffect, useLayoutEffect } from 'react'
import ReactGA from 'react-ga'
import Switch from 'react-switch'


function Toggle() {
    const [checked, setChecked] = useState(true)

    useLayoutEffect(() => {
        if (window.localStorage.getItem('lang') === 'en') {
            setChecked(true)
        }
        else if (window.localStorage.getItem('lang') === 'fr') {
            setChecked(false)
        } else {
            setChecked(true)
        }
    }, [])

    const [smallHeader, setSmallHeader] = useState(false)

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setSmallHeader(true)
        } else {
            setSmallHeader(false)
        }
    }

    window.addEventListener('scroll', handleScroll)

    const handleToggle = (state) => {

        setChecked(state)

        if (!state) {
            sendAnalytics("language toggle", "click to change language", "english to french")
            window.localStorage.setItem('lang', 'fr')
            window.location.href = "/fr"

        } else {
            sendAnalytics("language toggle", "click to change language", "french to english")
            window.localStorage.setItem('lang', 'en')
            window.location.href = "/en"
        }

    }

    function sendAnalytics(category, event, label) {
        ReactGA.event({
            category: category,
            action: event,
            label: label
        });
    }

    return (
        <div className={smallHeader ? "lang_toggle lang_toggle_off" : "lang_toggle"}>
            <label>
                <Switch
                    onChange={handleToggle}
                    checked={checked}
                    className="react-switch"
                    offColor="#444"
                    onColor="#444"
                    offHandleColor="#eaeaea"
                    onHandleColor="#eaeaea"
                    uncheckedIcon={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 15,
                                color: "#fff",
                                paddingRight: 2
                            }}
                        >
                            FR
                            </div>
                    }
                    checkedIcon={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 15,
                                color: "#fff",
                                paddingRight: 2
                            }}
                        >
                            EN
                            </div>
                    }
                />
            </label>
        </div>
    )
}

export default Toggle