import React from 'react'

import Header from '../../components/header/Header'
import HeroCarousel from '../../components/heroCarouselSlick/HeroCarousel'
import Category from '../../components/categorySlick/Category'
// WE SHOULD BE LOADING ONLY COMPONENTS INSIDE PAGES.

import categories from '../../data/categories.json'
import Footer from '../../components/footer/Footer'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import gtag, { install } from 'ga-gtag';

function Home() {
    ReactGA.initialize('UA-163442254-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    install('DC-2614879');

    if (window.localStorage.getItem('lang') == 'fr') {
        gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-2614879/mainp0/ketch00+standard'
        });
    } else {
        gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-2614879/mainp0/ketch0+standard'
        });
    }

    const { body } = document;
    const script = document.createElement('noscript');
    if (window.localStorage.getItem('lang') == 'fr') {
        script.text = '<img src="https://ad.doubleclick.net/ddm/activity/src=2614879;type=mainp0;cat=ketch00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/>';
    } else {
        script.text = '<img src="https://ad.doubleclick.net/ddm/activity/src=2614879;type=mainp0;cat=ketch0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/>';
    }
    body.insertBefore(script, body.firstChild);

    // FLoodlight tags
    const tagManagerArgs = {
        gtmId: 'GTM-N96B3B7'
    }
    TagManager.initialize(tagManagerArgs)

    const lang = window.localStorage.getItem('lang')
    const category = categories.map(data => {
        let title = lang === "en" ? data.category_title_en : data.category_title_fr
        return (
            <Category categoryTitle={title} movies={data.videos} key={Math.random() * 10} />
        )
    })

    return (
        <>
            <div className="bgFix"></div>
            <Header />
            <HeroCarousel />
            <div className="homeContent posterContainer">
                {category}
            </div>
            <Footer />
        </>
    )
}

export default Home