import React from 'react'
import ReactGA from 'react-ga'

import DoritosLogo from '../../assets/img/doritos_logo.svg'
import app from '../../data/app.json'
import Social from '../social/Social'

function Footer() {

    const lang = window.localStorage.getItem('lang')
    const langData = lang === "en" ? app[0].en : app[0].fr

    function sendAnalytics(category, event, label) {
        ReactGA.event({
            category: category,
            action: event,
            label: label
        });
    }

    const footerLinks = langData.links.footer.map(data => {
        return (
            <li key={Math.random() * 10}><a href={data.href} target="_blank" rel="noopener noreferrer" className="no_underline" onClick={() => sendAnalytics("footer", "legal", data.ga_label)}>{data.link_text}</a></li>
        )
    })

    return (
        <div className="footer">

            <div className="footerRow">

                <div className="footerCol taglineCol ">
                    <div className="taglineCol taglineColLeft">
                        <span className="hungry no-wrap">{langData.text.hungry}</span>
                    </div>
                    <div className="taglineCol taglineColRight" onClick={() => { window.open('https://www.walmart.ca/' + lang + '/ip/doritos-ketchup-tortilla-chips/6000196078571') }}>
                        <button className="hide-s hero_cta footerHero" onClick={() => sendAnalytics("get chips", "click on product cta", "footer")}>{langData.text.get_chips}</button>
                    </div>
                </div>

                <div className="footerCol ctaCol"  onClick={() => { window.open('https://www.walmart.ca/' + lang + '/ip/doritos-ketchup-tortilla-chips/6000196078571') }}>
                    <button className="hero_cta footerHero hide-xl" onClick={() => { sendAnalytics("get chips", "click on product cta", "footer") }}>{langData.text.get_chips}</button>
                </div>

                <div className="footerCol socialCol">
                    <div className="socialColContainer">
                        <Social source="site from footer" /><br />
                        <span className="footer_share_links_text">{langData.text.share_site}</span>
                    </div>
                </div>

            </div>

            <div className="footerRow">
                <div className="footerCol logoCol">
                    <img src={DoritosLogo} alt="Doritos" className="doritosLogo" onClick={() => { let dlang = ((lang === 'en') ? "" : "fr"); window.open('https://www.doritos.ca/' + dlang) }} />
                </div>

                <div className="footerCol linksCol">
                    <ul className="footerLinks">
                        {footerLinks}
                    </ul>
                </div>

                <div className="footerCol copyRightCol">
                    <div className="copyright">
                        {langData.text.legal}
                    </div>
                </div>
            </div>

        </div >
    )

}

export default Footer