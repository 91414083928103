import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, useParams } from "react-router-dom"
// Site components
import Category from '../../components/categorySlick/Category'

// DB Data
import categories from "../../data/categories.json"
import videoData from '../../data/videos.json'
import videoWatchNext from '../../data/watch-next.json'
import SingleHero from '../../components/singleHero/SingleHero';

function Movie() {

    const { video_id } = useParams()
    const lang = window.localStorage.getItem('lang')
    const currentData = videoData[0][video_id]
    const text = lang === "en" ? currentData.text_en : currentData.text_fr

    // eslint-disable-next-line
    const category = categories.map(data => {
        let title = lang === "en" ? data.category_title_en : data.category_title_fr
        return (
            <Category key={Math.random()} categoryTitle={title} movies={data.videos} />
        )
    })


    return (
        <>
            <SingleHero title={text.title} sumary={text.description} meta={text.meta} backdrop_poster={currentData.video_drop} title_image={currentData.title_image} movie_link={video_id} vertical={currentData.vertical_title} />
            <div className="singleContent posterContainer">
                <Category categoryTitle={lang === "en" ? currentData.next.title_en : currentData.next.title_fr} movies={videoWatchNext} isNext="next" video_id={video_id} />
                {/* {category} */}
            </div>

            <div style={{ height: "50px" }}></div>
        </>
    )
}

export default Movie