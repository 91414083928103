import React, { useState } from 'react'
import * as moment from 'moment'

import appData from '../../data/app.json'

export default function AgeForm() {

    // TODO: VERIFY IF PREVIOUS URL IS AGE OR NOT.

    const [day, setDay] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')

    const lang = window.localStorage.getItem('lang')
    const textData = lang === "en" ? appData[0].en : appData[0].fr

    const validateYear = (e) => {
        if (e < 1870) {
            setYear(1870)
        }
    }

    const validateDay = (e) => {
        if (e < 1) {
            setDay(1)
        }

        if (e > 31) {
            setDay(31)
        }
    }

    const validateMonth = (e) => {
        if (e < 1) {
            setMonth(1)
        }

        if (e > 12) {
            setMonth(12)
        }
    }

    const handleSubmit = () => {
        function zeroPad(num, numZeros) {
            var n = Math.abs(num);
            var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
            var zeroString = Math.pow(10, zeros).toString().substr(1);
            if (num < 0) {
                zeroString = '-' + zeroString;
            }

            return zeroString + n;
        }
        let date = year + '-' + zeroPad(month, 2) + '-' + zeroPad(day, 2)


        let years = moment().diff(moment(date, "YYYY-MM-DD"), 'years')

        //si la edad es menor a 16, mostrar error
        if (years < 16 || years > 120 || Number.isNaN(years)) {
            window.location = '/' + lang + '/age/error'
            window.localStorage.removeItem('age')
        } else { //mas de 16, setear ok, continuar con todo
            window.sessionStorage.setItem('age', years)
            if (window.localStorage.getItem('prev_url')) {
                window.location = window.localStorage.getItem('prev_url')
            } else {
                window.location = '/'
            }
        }
    }

    if (lang == 'fr') {
        return (

            <div className="ageForm">
                <div className="formInfo">
                    {textData.text.age_gate1}<br /><br />
                    {textData.text.age_gate2}<br />
                    {textData.text.age_gate3}
                </div>

                <div className="formTitle">
                    {textData.text.age_gate_form_title}
                </div>
                <div className="inputs">
                    <input type="text" id="day" maxLength="2" inputMode="numeric" onBlur={e => validateDay(e.target.value)} name="d" onChange={e => setDay(e.target.value)} placeholder={textData.text.age_gate_day} required value={day} />
                    <input type="text" id="month" maxLength="2" inputMode="numeric" onBlur={e => validateMonth(e.target.value)} onChange={e => setMonth(e.target.value)} name="m" placeholder={textData.text.age_gate_month} required value={month} />
                    <input type="text" day="year" maxLength="4" inputMode="numeric" onBlur={e => validateYear(e.target.value)} name="y" onChange={e => setYear(e.target.value)} placeholder={textData.text.age_gate_year} required value={year} />
                </div>

                <div className="sendButton">
                    <div>
                        <button id="ageSubmit" className="hero_cta" onClick={handleSubmit}>{textData.text.age_gate_form_enter}</button>
                    </div>
                </div>
            </div >
        )
    } else {

        return (
            <div className="ageForm">
                <div className="formInfo">
                    {textData.text.age_gate1}<br /><br />
                    {textData.text.age_gate2}<br />
                    {textData.text.age_gate3}
                </div>

                <div className="formTitle">
                    {textData.text.age_gate_form_title}
                </div>
                <div className="inputs">
                    <input type="text" id="month" maxLength="2" inputMode="numeric" onBlur={e => validateMonth(e.target.value)} onChange={e => setMonth(e.target.value)} name="m" placeholder={textData.text.age_gate_month} required value={month} />
                    <input type="text" id="day" maxLength="2" inputMode="numeric" onBlur={e => validateDay(e.target.value)} name="d" onChange={e => setDay(e.target.value)} placeholder={textData.text.age_gate_day} required value={day} />
                    <input type="text" day="year" maxLength="4" inputMode="numeric" onBlur={e => validateYear(e.target.value)} name="y" onChange={e => setYear(e.target.value)} placeholder={textData.text.age_gate_year} required value={year} />
                </div>

                <div className="sendButton">
                    <div>
                        <button id="ageSubmit" className="hero_cta" onClick={handleSubmit}>{textData.text.age_gate_form_enter}</button>
                    </div>
                </div>
            </div>
        )
    }
}
