import React, { useEffect, useLayoutEffect } from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ScrollToTop from './share/scrollToTop/ScrollToTop'

import './reset.css'
import './App.css'
import './Responsive.css'

// LOAD OUR PAGES COMPONENTS
import Home from './pages/home/Home'
import Movie from './pages/movie/Movie'
import Play from './pages/play/Play'
import AgeGate from './pages/ageGate/AgeGate'
import AgeError from './pages/ageGate/AgeError'
import getUserLocale from 'get-user-locale';

function App() {

	ReactGA.initialize('UA-163442254-1')

	useLayoutEffect(() => {
		// let bgimage = document.getElementsByTagName("html")[0].style.backgroundImage
		// document.getElementsByTagName("html")[0].style.backgroundImage = "none"
		let location = urlFix()
		if (location) {
			window.location.pathname = location
		} else {
			if (window.location.pathname == '/') {

			} else {
				document.getElementsByTagName("html")[0].style.visibility = "visible";
				document.getElementsByTagName("html")[0].style.backgroundImage = "url('/img/shutterstock_1358731034.jpg')";
			}
		}


	}, [])

	const urlFix = () => {
		let path = window.location.pathname
		let path_lang = path.split('/')[1]
		let userLocale = getUserLocale()
		// console.log(userLocale)
		let location = false;
		if (path_lang) {
			if (path_lang !== window.localStorage.getItem('lang') && (path_lang === "en" || path_lang === "fr")) {
				// url is different, need to change localstorage and reload web.
				window.localStorage.setItem('lang', path_lang)
				window.location.reload()
				return location
			}
			// if there is no age setted
			if (window.sessionStorage.getItem('age')) {
				// hay edad, continuar
				// is he/she 16?
				// if (window.sessionStorage.getItem('age') < 16 ) {
				// 	// no he/she's not
				// 	window.location.pathname = `/${window.localStorage.getItem('lang')}/age/error`
				// } else {
				// 	// he is, do nothing.
				// }
			} else {

				if (path.startsWith('/en/age') || path.startsWith('/fr/age')) {

				} else {
					console.log("should redirect to age")

					window.localStorage.setItem('prev_url', window.location)
					// window.location.pathname = "/" + window.localStorage.getItem('lang') + "/age/"
					location = "/" + window.localStorage.getItem('lang') + "/age/"
					return location
				}

				// age has not been seted, lets show form
				// let agePath = path.split('/')[2]
				// // console.log(path)
				// // console.log(agePath)c
				// // don`t reload if already here
				// if (agePath !== 'age') {
				// 	console.log(path)
				// 	window.location.pathname = `/${window.localStorage.getItem('lang')}/age?`
				// }
			}
		} else {
			if (!window.localStorage.getItem('lang')) {

				if (userLocale.startsWith('fr')) {
					window.localStorage.setItem('lang', 'fr')
				} else {
					window.localStorage.setItem('lang', 'en')
				}

			}
			// window.location.pathname = `/${window.localStorage.getItem('lang')}`
			location = `/${window.localStorage.getItem('lang')}`
			return location
		}

		return location
	}

	// if ( window.localStorage.getItem('lang') ) {
	// 	// validate if age is nbumer too
	// 	let path = window.location.pathname
	// 	let resource = path.split('/')[2]

	// 	if (window.sessionStorage.getItem('age') < 16  && resource !== "age" ) {
	// 		window.location = '/'+window.localStorage.getItem('lang')+'/age/error'
	// 	}

	// 	if ( window.sessionStorage.getItem('age') === null && resource !== "age" ) {

	// 			window.localStorage.setItem('prev_url', window.location )
	// 			window.location.pathname = window.localStorage.getItem('lang')+"/age"

	// 	}
	// }

	return (
		<Router>
			<ScrollToTop />
			<Switch>
				<Route exact path={"/" + window.localStorage.getItem('lang')}>
					<Home />
				</Route>

				<Route exact path={"/" + window.localStorage.getItem('lang') + "/movie/:video_id"} >
					<Movie />
				</Route>

				<Route exact path={"/" + window.localStorage.getItem('lang') + "/movie/:video_id/play"}>
					<Play />
				</Route>

				<Route exact path={"/" + window.localStorage.getItem('lang') + "/age"}>
					<AgeGate />
				</Route>

				<Route exact path={"/" + window.localStorage.getItem('lang') + "/age/error"}>
					<AgeError />
				</Route>



				<Route>
					{/* <Home /> */}
				</Route>

			</Switch>
		</Router>
	)
}

export default App
