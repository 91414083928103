
import React from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Link, useParams } from "react-router-dom"

import VideoPlayer from '../../components/videoPlayer/VideoPlayer'
import closeButton from '../../assets/img/close.png'

import videoData from '../../data/videos.json'
import ReactGA from 'react-ga';

export default function Play() {

    ReactGA.initialize('UA-163442254-1');

    function sendAnalytics(category, event, label) {
        ReactGA.event({
            category: category,
            action: event,
            label: label
        });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    let { video_id } = useParams()
    const currentData = videoData[0][video_id]
    let show_fr = false
    let show_en = false
    if (window.localStorage.getItem('lang') === "fr" && "en" === currentData.video_language) {
        //if current language is french and video is english, show fr subs
        show_fr = true
        show_en = false
    }
    if (window.localStorage.getItem('lang') === "en" && "fr" === currentData.video_language) {
        //if current language is english and video is french, show english subtitles
        show_fr = false
        show_en = true
    }

    return (
        <div className="videoContainer">
            <Link to={"/" + window.localStorage.getItem('lang') + "/movie/" + video_id}>
                <img src={closeButton} alt="Close" id="close-player-icon" className="close-player-icon" onClick={() => sendAnalytics("video player", "closed", video_id)} />
            </Link>
            <VideoPlayer video_id={video_id} video_url={currentData.video_url} video_txt_fr={currentData.video_txt_fr} video_txt_en={currentData.video_txt_en} show_txt_en={show_en} show_txt_fr={show_fr} />
        </div>
    )
}