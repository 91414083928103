import React, { useState } from 'react'

import DKPLogo from '../../assets/img/app_logo.svg'
import Toggle from '../toggle/Toggle'
function Header() {

    const [smallHeader, setSmallHeader] = useState(false)

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setSmallHeader(true)
        } else {
            setSmallHeader(false)
        }
    }

    window.addEventListener('scroll', handleScroll)

    return (
        <nav className="header">
            <div className={smallHeader ? 'headerLogoDKP smallLogo' : 'headerLogoDKP'}>
                <img src={DKPLogo} alt="Doritos Ketchup+" onClick={() => { window.location = '/' + window.localStorage.getItem('lang') }} />
            </div>

            <div className="headerToggle">
                <Toggle />
            </div>
        </nav>
    )
}

export default Header