import React from 'react'
import ReactGA from 'react-ga'
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom"

import PlayIcon from '../../assets/img/godly_play_button.png'

function PlayButton(props) {

    const { movie_id } = props
    const linkDestination = `/${window.localStorage.getItem('lang')}/movie/${movie_id}/play`

    function sendAnalytic() {
        ReactGA.event({
            category: "video play",
            action: "play home page video",
            label: movie_id
        })
        window.location.pathname = linkDestination
    }

    return (

            <div className="playButton" onClick={sendAnalytic}>
                <img src={PlayIcon} alt="Play Video" className="playIcon" />
            </div>
    )
}

export default PlayButton