import React from 'react'
import DKPLogo from '../../assets/img/app_logo.svg'
import appData from '../../data/app.json'
import ReactGA from 'react-ga'
export default function AgeError() {
    ReactGA.initialize('UA-163442254-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    const lang = window.localStorage.getItem('lang')
    const textData = lang === "en" ? appData[0].en : appData[0].fr
    window.localStorage.removeItem('age')

    return (
        <div className="ageGateContainer">
            <div className="ageGate">
                <div className="ageGateLogo">
                    <a href="../age"> <img src={DKPLogo} alt="Doritos Ketchup+" /></a>
                </div>
                <div className="ageGateContent">
                    <div className="ageGateError">
                        {textData.text.age_gate_error}
                    </div>
                </div>
            </div>
        </div>
    )
}
