import React, { useState, useEffect, useLayoutEffect } from 'react'
import Slider from 'react-slick'
import UAParser from 'ua-parser-js'
import ReactGA from 'react-ga'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'



function Category(props) {

    // eslint-disable-next-line 
    const { categoryTitle, movies, isNext, video_id } = props

    const [moving, setMoving] = useState(false)
    const [posters, setPosters] = useState(false)

    const a = new UAParser()
    const isMobile = a.getDevice().type === "mobile" || a.getDevice().type === "tablet"

    let firstClientX
    let firstClientY
    let clientX

    // eslint-disable-next-line
    let clientY

    useLayoutEffect(() => {
        // eslint-disable-next-line 

        window.addEventListener('touchstart', touchStart);
        window.addEventListener('touchmove', preventTouch, { passive: false });


        // eslint-disable-next-line
        if (!posters) {
            // execute just one time on load
            if (isNext) {
                shuffleArray(movies)
            }
            let nextMovies = movies.filter(function (item) {
                if (item.video_id === video_id) {
                    return false
                }
                return true
            });
            setPosters(nextMovies.map(x => {
                return (
                    <img key={Math.random() * 20} src={x.poster_url} alt={x.video_id} className="poster" onClick={(e) => { if (!moving) sendTileAnalytics(isNext, x.video_id) }} />
                )
            }))
        }
        // eslint-disable-next-line 
    }, [])

    // eslint-disable-next-line
    const touchStart = (e) => {
        firstClientX = e.touches[0].clientX;
        firstClientY = e.touches[0].clientY;
    }

    // eslint-disable-next-line
    const preventTouch = (e) => {
        const minValue = 20
        clientX = e.touches[0].clientX - firstClientX;
        clientY = e.touches[0].clientY - firstClientY;
        if (isMobile) {
            if (Math.abs(clientX) > minValue) {
                console.log('you shall not scroll!')
                e.preventDefault();
                e.returnValue = false;
                return false;
            }
        }
    }


    // eslint-disable-next-line 
    function sendAnalytics(category, event, label) {
        ReactGA.event({
            category: category,
            action: event,
            label: label
        });
    }

    function sendTileAnalytics(isNext, video_id) {
        if (!moving) {
            if (isNext === "next") {
                sendAnalytics("video detail", "click watch next", video_id);
                window.location = `/${window.localStorage.getItem('lang')}/movie/${video_id}`
            } else {
                sendAnalytics("video tile", "click video tile", video_id);
                window.location = `/${window.localStorage.getItem('lang')}/movie/${video_id}`
            }
        }
    }
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }





    const responsive = [
        {
            breakpoint: 950,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }

        },
        {
            breakpoint: 490,
            settings: {
                slidesToShow: 3.3,
                slidesToScroll: 3.3,
            }

        }
    ]

    const settings = {
        dots: false,
        infinite: false,
        draggable: false,
        speed: 900,
        easing: 'linear',
        arrows: !isMobile,
        slidesToShow: 5,
        slidesToScroll: ((isMobile) ? 3 : 1),
        beforeChange: () => setMoving(true),
        afterChange: () => setMoving(false),
        responsive: responsive,
        touchmove: !isMobile,
        edgeFriction: 0.5,
        touchThreshold: 10,
        waitForAnimate: false,


    }


    return (
        <div className="posterRow">
            <div className="posterRowTitle">
                {categoryTitle}
            </div>
            <Slider {...settings}>
                {posters}
            </Slider>
        </div>
    )
}

export default Category