import React from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom"
import ReactGA from 'react-ga'

import PlayButton from '../playButton/PlayButton'
import Social from '../social/Social'

import closeIcon_en from '../../assets/img/home-en.png'
import closeIcon_fr from '../../assets/img/home-fr.png'
import appData from '../../data/app.json'


export default function SingleHero(props) {
    ReactGA.initialize('UA-163442254-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    function sendAnalytics(category, event, label) {
        ReactGA.event({
            category: category,
            action: event,
            label: label
        });
    }
    const { title, backdrop_poster, title_image, movie_link, sumary, meta, vertical } = props
    const lang = window.localStorage.getItem('lang')
    const langData = lang === "en" ? appData[0].en : appData[0].fr

    let closeIconImage
    if (lang == 'en') {
        closeIconImage = closeIcon_en
    } else {
        closeIconImage = closeIcon_fr
    }

    return (
        <>
            <div className="singleHero">

                <div className="singleHeroImageContainer">

                    <div className="singleHeroGradient"></div>

                    <Link key={Math.random() * 20} to={`/${lang}`}>
                        <img src={closeIconImage} alt="Go Back" className="movieClose" onClick={() => sendAnalytics("video detail", "close", movie_link)} />
                    </Link>

                    <div className="singleHeroPlay hide-s">
                        <PlayButton movie_id={movie_link} />
                    </div>

                    <img src={backdrop_poster} alt={title} className="singleHeroImage" />
                    <div className="singleHeroContent">
                        <div className="singleHeroCol">

                            <div className="singleMovieInfo">
                                <div className="singleMoviveInfoContent">

                                    <div className="singleHeroPlay hide-xl">
                                        <PlayButton movie_id={movie_link} />
                                    </div>

                                    <div className={vertical ? 'movieSlideverticalTitle ' + movie_link : 'movieSlideTitle ' + movie_link}>
                                        <img src={title_image} alt={title} />
                                    </div>

                                    <div className="movieSumary" dangerouslySetInnerHTML={{ __html: sumary }}>

                                    </div>

                                    <div className="movieMeta">
                                        {meta}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="singleHeroCol singleHeroColSocial">
                            <div className="singleHeroSocial">
                                <Social source={movie_link} />
                                <div className="singleSocialTag">{langData.text.share_video}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        // <div className="movieSlide" style={{ backgroundImage: `url(${backdrop_poster})` }}>


        //     <div className="movieSlideGradient"></div>



        //     <div className="movieSlideContent">

        //         <div className="movieSlideCol">

        //             <div className="movieInfo singleMovieInfo">

        //                 <div className="movieSlideTitle">
        //                     <img src={title_image} alt={title} />
        //                 </div>

        //                 <div className="movieSumary">
        //                     {sumary}
        //                 </div>

        //                 <div className="movieMeta">
        //                     {meta}
        //                 </div>

        //                 <div className="movieMeta metaSocial hide-xl">
        //                     <Social source={movie_link} />
        //                     <div className="singleSocialTag">{langData.text.share_video}</div>
        //                 </div>

        //             </div>

        //         </div> { /* movieSlideCol */}

        //         <div className="movieSlideCol movieSlidePlayCol">
        //             <div className="movieSlidePlay smallPlay hide-xl">
        //                 <PlayButton movie_id={movie_link} />
        //             </div>
        //             <div className="singleSocial hide-s">
        //                 <Social source={movie_link} />
        //                 <div className="singleSocialTag">{langData.text.share_video}</div>
        //             </div>
        //         </div> { /* movieSlideCol */}

        //     </div> { /* movieSlideContent */}

        // </div>
    )
}

